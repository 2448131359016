import React, { useContext, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import I from 'immutable'
import { TableBody, Table, TableContainer, CircularProgress, TableHead, TableRow, Paper, TableCell } from '@material-ui/core'
import Context from 'reactContext'
import Agent from 'components/agent'
import { get } from 'utils/request'

const Rewards = () => {
  const { users, usersSet } = useContext(Context)
  const [dataLoading, setDataLoading] = useState(true)
  const [salesRewards, setSalesRewards] = useState([])

  const getSalesRewards = async () => {
    const res = await get('agents/sales_bonus_rewards')
    if (res.ok) {
      setSalesRewards(res.data)
      setDataLoading(false)
    }
  }

  const usersIndex = async () => {
    const res = await get('agents/managers')
    if (res.ok) {
      usersSet(I.fromJS(res.data))
    }
  }

  useEffect(() => {
    getSalesRewards()
    usersIndex()
  }, [])

  return (
    <Agent>
      <div className="text-center">
        {dataLoading ? (
          <CircularProgress size={30} />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>公司名称</TableCell>
                  <TableCell>返现时间</TableCell>
                  <TableCell>金额</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesRewards.map((v) => (
                  <TableRow key={v.created_at}>
                    <TableCell align="left">
                      {users
                        .filter((u) => u.get('id') === v.admin_id)
                        .map((u) => u.get('name'))
                        .toArray().length === 0
                        ? ''
                        : users
                            .filter((u) => u.get('id') === v.admin_id)
                            .map((u) => u.get('name'))
                            .toArray()[0][1]}
                    </TableCell>
                    <TableCell align="left">{dayjs(v.created_at).format('YY-MM-DD HH:mm')}</TableCell>
                    <TableCell align="left">{` ${v.data.amount / 100}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Agent>
  )
}

export default Rewards
